/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Redirect, Switch} from "react-router-dom";
import { Auth, Amplify } from 'aws-amplify';
import config from './config';

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
// pages
import Classified from "views/classified.js";
import List from "views/list.js";
// others
var AWS = require("aws-sdk");

Amplify.configure({
    API: {
        endpoints: [
            {
                name: "entities",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            },
        ]
    }
});

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route
                path="/cfList"
                render={props => <List {...props} />}
            />
            <Route
                path="/"
                render={props => <Classified {...props} search={window.location.search} />}
            />
            <Redirect to={"/index" + window.location.search}/>
        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
