/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// reactstrap components
import {
    Button, Col,
    Modal
} from "reactstrap";

// core components

function LegalModalWindow() {
    const [modal, setModal] = React.useState(false);
    const toggleModal = () => {
        setModal(!modal);
    };
    return (
        <>
            <Button className="btn-link ml-1" color="black" type="button" onClick={toggleModal}>
                Impressum, Datenschutz und Nutzungsbedingungen
            </Button>
            {/* Modal */}
            <Modal isOpen={modal} toggle={toggleModal}>
                <div className="modal-header">
                    <button
                        aria-label="Close"
                        className="close"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                    <h5
                        className="modal-title text-center"
                        id="exampleModalLabel"
                    >
                        Impressum
                    </h5>
                </div>
                <div className="modal-body">
                    <p>mosoco GmbH<br/>
                        Borodinstraße 14<br/>
                        13088 Berlin
                    </p>

                    <p>
                        Telefon: 0152-03949276<br/>
                        E-Mail: <a href="mailto:nils.koester@mosoco.de">nils.koester@mosoco.de</a>
                    </p>

                    <p>
                        Vertreten durch:<br/>
                        Vitali Neubauer<br/>
                        Nils Köster<br/>
                    </p>

                    <p>
                        Eingetragen im Handelsregister:<br/>
                        Register-Nr: HRB Eintragung Beantragt<br/>
                        Gericht: Berlin Charlottenburg
                    </p>
                    <br/><br/><br/>
                    <p><b><span>Datenschutzerklärung</span></b></p>

                    <p><b><span
                    >Datenschutz</span></b></p>

                    <p><span>Wir haben diese
Datenschutzerklärung (Fassung 28.05.2020-311181124) verfasst, um Ihnen gemäß
der Vorgaben der&nbsp;<a
                            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311181124"
                            target="_blank"><span>Datenschutz-Grundverordnung (EU) 2016/679</span></a>&nbsp;zu
erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche
Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.</span></p>

                    <p><span>Leider liegt es in der
Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns
bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie
möglich zu beschreiben.</span></p>

                    <p><b><span
                    >Automatische
Datenspeicherung</span></b></p>

                    <p><span>Wenn Sie heutzutage
Webseiten besuchen, werden gewisse Informationen automatisch erstellt und
gespeichert, so auch auf dieser Webseite.</span></p>

                    <p><span>Wenn Sie unsere
Webseite so wie jetzt gerade besuchen, speichert unser Webserver (<span
                            className="GramE">Computer</span> auf dem diese Webseite gespeichert ist) automatisch
Daten wie</span></p>

                    <ul type="disc">
                        <li><span>die
     Adresse (URL) der aufgerufenen Webseite</span></li>
                        <li><span>Browser
     und Browserversion</span></li>
                        <li><span>das
     verwendete Betriebssystem</span></li>
                        <li><span>die
     Adresse (URL) der zuvor besuchten Seite (<span className="SpellE">Referrer</span>
     URL)</span></li>
                        <li><span>den
     Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</span></li>
                        <li><span>Datum und
     Uhrzeit</span></li>
                    </ul>

                    <p><span>in Dateien
(Webserver-Logfiles).</span></p>

                    <p><span>In der Regel werden
Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir
geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese
Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</span></p>

                    <p><b><span
                    >Rechte
laut Datenschutzgrundverordnung</span></b></p>

                    <p><span>Ihnen stehen laut den
Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</span></p>

                    <ul type="disc">
                        <li><span>Recht auf
     Berichtigung (Artikel 16 DSGVO)</span></li>
                        <li><span>Recht auf
     Löschung („Recht auf <span className="SpellE">Vergessenwerden</span>“) (Artikel
     17 DSGVO)</span></li>
                        <li><span>Recht auf
     Einschränkung der Verarbeitung (Artikel 18 DSGVO)</span></li>
                        <li><span>Recht auf
     Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung
     oder Löschung personenbezogener Daten oder der Einschränkung der
     Verarbeitung (Artikel 19 DSGVO)</span></li>
                        <li><span>Recht auf
     Datenübertragbarkeit (Artikel 20 DSGVO)</span></li>
                        <li><span>Widerspruchsrecht
     (Artikel 21 DSGVO)</span></li>
                        <li><span>Recht,
     nicht einer ausschließlich auf einer automatisierten Verarbeitung —
     einschließlich <span className="SpellE">Profiling</span> — beruhenden
     Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</span></li>
                    </ul>

                    <p><span>Wenn Sie glauben, dass
die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind,
können Sie sich an die&nbsp;<a href="https://www.bfdi.bund.de" target="_blank"><span>Bundesbeauftragte für den Datenschutz und die
Informationsfreiheit (<span className="SpellE">BfDI</span>)</span></a>&nbsp;wenden.</span></p>

                    <p><b><span
                    >Auswertung
des Besucherverhaltens</span></b></p>

                    <p><span>Wir können von Ihrem Verhalten auf dieser Website nicht
auf Ihre Person schließen.</span></p>

                    <p><span>Wir verwenden keine Cookies und verarbeiten keine personenbezogenen Daten. Wir verwenden keine Technologien oder Produkte von Drittanbietern welche Daten über die USer sammeln und/oder verarbeiten.</span></p>

                    <p><b><span>Amazon Web Services</span></b></p>

                    <p><span>
                    Zur Benutzerauthentifizierung wird der Dienst Amazon Cognito von Amazon Web Services verwendet. Amazon Web Services erhält deshalb die zum Login benötigten Informationen. Das sind die bei der Registrierung angegebene E-Mail-Adresse, der Benutzername sowie das Passwort in verschlüsselter Form. Dahingegen wird von uns kein Passwort gespeichert. Weitere Informationen zu den Datenschutzrichtlinien von Amazon Web Services finden Sie unter https://aws.amazon.com/de/privacy.
                    </span></p>

                    <p><span>Quelle: Erstellt&nbsp;mit dem&nbsp;<a
                        href="https://www.adsimple.de/datenschutz-generator/" target="_blank"
                        title="Datenschutz Generator Deutschland"><span>Datenschutz
Generator</span></a>&nbsp;von <span className="SpellE">AdSimple</span>&nbsp;in
Kooperation mit&nbsp;<a href="https://www.justmed.de" target="_blank"><span>justmed.de</span></a></span></p>

                </div>

                <div className="modal-footer">
                    <div className="left-side">
                        <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={toggleModal}
                        >
                            OK
                        </Button>
                    </div>
                    {/*<div className="divider"/>*/}
                    {/*<div className="right-side">*/}
                    {/*    <Button className="btn-link" color="danger" type="button">*/}
                    {/*        Delete*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>
            </Modal>
        </>
    );
}

export default LegalModalWindow;
