import React from "react";
import {properties} from "components/Helpers/generalProperties";
import {Col} from "reactstrap";

function Summary(props) {
    return (
        <>
            <div className="owner">
                <div className="avatar">
                    <img
                        alt="..."
                        className="img-rounded img-responsive"
                        src={properties.media+props.cfData.objectID+"_icon.jpg"}
                    />
                </div>
                <div className="name">
                    <h4 className="title">
                        {props.cfData.carEntry.street}, {props.cfData.carEntry.cityDistrict}, {props.cfData.carEntry.city}<br/>
                    </h4>
                    <h6 className="description">{props.cfData.type}</h6>
                </div>
            </div>
            <Col className="text-left ml-auto mr-auto" md="6">
                {props.cfData.summary}
            </Col>
        </>
    )
}

export default Summary;