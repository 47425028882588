var backend = '';
var media = '';
var views = '';
var check = '';

switch (process.env.NODE_ENV) {
    case "development":
        backend = "http://" + window.location.hostname + ":3004/";
        media = "http://" + window.location.hostname + ":3003/";
        views = "http://" + window.location.hostname + ":8081/index.html";
        check ="dev";
        break;

    case "production":
        backend = "https://mosoco-mockbackend.s3-website.eu-central-1.amazonaws.com/";
        media = "https://media.mosoco.de/";
        views = "https://360.mosoco.de/index.html";
        check ="prod";
        break;

    default:
        backend = "http://" + window.location.hostname + ":3004/";
        media = "http://" + window.location.hostname + ":3003/";
        views = "http://" + window.location.hostname + ":8081/index.html";
        check ="default";
        break;
}

var properties = {
    backend: backend,
    media: media,
    views: views,
    check: check
};

module.exports = {
    properties
}