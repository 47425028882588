import React from "react"
import {Col, Nav, NavItem, NavLink} from "reactstrap";
import Iframe from "react-iframe";
import {properties} from "components/Helpers/generalProperties";
import vrIframe from "./Views.css";


function Views(props) {

    const [activeView, setActiveView] = React.useState(0);

    const toggleView = view => {
        if (activeView !== view) {
            setActiveView(view);
        }
    };

    function renderNavItems(data) {

        return (
            data.views.map(
                (view, index) => {
                    return (
                        <NavItem>
                            <NavLink
                                className={activeView === index ? "active" : ""}
                                onClick={() => {
                                    toggleView(index);
                                }}
                            >
                                {view.title}
                            </NavLink>
                        </NavItem>
                    )
                }
            )
        )
    }

    return (
        <>
            <Col className="text-left ml-auto mr-auto" md="8">
                <div className="nav-tabs-navigation" style={{
                    "margin-bottom": "5px"
                }}>
                    <div className="nav-tabs-wrapper">
                        <Nav role="tablist" tabs>
                            {renderNavItems(props.cfData)}
                        </Nav>
                    </div>
                </div>
            </Col>

            <Col className="text-left ml-auto mr-auto" md="8">
                <div style={{position: "relative", width: "100%"}}>
                    <div style={{"padding-bottom": "57%"}}>
                        <div className="vrIframe">

                            <Iframe
                                url={properties.views + "?test=test&vrFile="+props.cfData.objectID+props.cfData.views[activeView].visual}
                                //  url={"/indexPage"}
                                // url={"http://mbp-nils:8081/index.html?cf=guertelstrasse"}
                                width={"100%"}
                                height={"100%"}
                                frameBorder={0}
                            />
                        </div>
                    </div>
                </div>
            </Col>


        </>
    )
}

export default Views;