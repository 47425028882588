import React from "react"

import {Col, Nav, NavItem, NavLink} from "reactstrap";

import Map from "pigeon-maps";
import Overlay from "pigeon-overlay";

const overviewMap = 1,
    carEntryMap = 2,
    pedestrianAccessMap = 3;

function getOverlay(lat, lon, type) {
    var icon = "🅿️";

    switch (type) {
        case overviewMap:
            break;

        case pedestrianAccessMap:
            icon = "🚶🏽‍♂️";
            break;

        case carEntryMap:
            icon = "🚘";
            break;

    }
    return (
        <Overlay anchor={[lat, lon]}>
            <b>
                <p style={{"font-size":20}}>
                    {icon}
                </p>
            </b>
        </Overlay>
    )
}

function renderOverlays(data, activeMap) {

    switch (activeMap) {
        case pedestrianAccessMap:
            return (
                data.pedestrianAccess.map(
                    (pedestrianAccess, index) => {
                        return (
                            getOverlay(pedestrianAccess.geo.lat, pedestrianAccess.geo.lon, activeMap)
                        )
                    }
                ))
            break;

        case carEntryMap:
            return (getOverlay(data.carEntry.geo.lat, data.carEntry.geo.lon, activeMap));
            break;

        case overviewMap:
            return (getOverlay(data.maps.overviewArea.lat, data.maps.overviewArea.lon, activeMap));
            break;

    }

    return (<></>)
}


function renderMap(data, activeMap) {
    var lat, lon, zoom;

    switch (activeMap) {
        case overviewMap:
            lat = data.maps.overviewArea.lat;
            lon = data.maps.overviewArea.lon;
            zoom = data.maps.overviewArea.zoom;
            break;

        case carEntryMap:
        case pedestrianAccessMap:
            lat = data.maps.detailArea.lat;
            lon = data.maps.detailArea.lon;
            zoom = data.maps.detailArea.zoom;
            break;
    }

    return (
        <Map center={[lat, lon]}
             zoom={zoom}
             onClick={({event, latLng, pixel} ) => {
                 console.log("\"lat\":"+latLng.toString().split(',')[0]+",\n" +
                     "      \"lon\":"+latLng.toString().split(',')[1]);
             }}
             provider={(x, y, z) => {
                 const s = String.fromCharCode(97 + (x + y + z) % 3)
                 return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`
             }}
        >
            {
                renderOverlays(data, activeMap)
            }
        </Map>
    )

}

function Maps(props) {

    const [activeMap, setActiveMap] = React.useState(overviewMap);

    const toggleMap = map => {
        if (activeMap !== map) {
            setActiveMap(map);
        }
    };

    return (
        <>
            <Col className="text-left ml-auto mr-auto" md="8">
                <div className="nav-tabs-navigation" style={{
                    "margin-bottom": "5px"
                }}>
                    <div className="nav-tabs-wrapper">
                        <Nav role="tablist" tabs>
                            <NavItem>
                                <NavLink
                                    className={activeMap === overviewMap ? "active" : ""}
                                    onClick={() => {
                                        toggleMap(overviewMap);
                                    }}
                                >
                                    Lage
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeMap === carEntryMap ? "active" : ""}
                                    onClick={() => {
                                        toggleMap(carEntryMap);
                                    }}
                                >
                                    Zufahrt
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeMap === pedestrianAccessMap ? "active" : ""}
                                    onClick={() => {
                                        toggleMap(pedestrianAccessMap);
                                    }}
                                >
                                    Zugänge
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
            </Col>
            <Col className="text-left ml-auto mr-auto" md="8">
                <div style={{position: "relative", width: "100%"}}>
                    <div style={{"padding-bottom": "57%"}}>
                        <div style={{
                            position: "absolute",
                            top: 0, bottom: 0, left: 0, right: 0,
                        }}>

                            {renderMap(props.cfData, activeMap)}

                        </div>
                    </div>

                </div>
            </Col>
        </>
    )
}


export default Maps;