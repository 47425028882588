export default {
    s3: {
        REGION: "eu-central-1",
        BUCKET: "entities-app-2-api-prod-attachmentsbucket-138uvh18sqjiv"
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://a7uun9887c.execute-api.eu-central-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_XATYitUG5",
        APP_CLIENT_ID: "rihlgu5dd00p60opqonl4aqqn",
        IDENTITY_POOL_ID: "eu-central-1:c2b59d14-4c13-4a41-b066-4dfa8b584ee1"
    }
};