import React from "react"
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import DimensionsModalWindow from "./DimensionsModalWindow";

function Details(props) {

    const [activeTab, setActiveTab] = React.useState("1");

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <>
            <Col className="text-left ml-auto mr-auto" md="8">
                <h6 className="description">Details</h6>
            </Col>
            <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                    <div className="nav-tabs-navigation">
                        <div className="nav-tabs-wrapper">
                            <Nav role="tablist" tabs>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === "1" ? "active" : ""}
                                        onClick={() => {
                                            toggle("1");
                                        }}
                                    >
                                        Beschreibung
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === "2" ? "active" : ""}
                                        onClick={() => {
                                            toggle("2");
                                        }}
                                    >
                                        Abmessungen
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                    <TabContent className="" activeTab={activeTab}>
                        <TabPane tabId="1" id="description">
                            <Col className="text-left ml-auto mr-auto" md="10">
                                <Row>
                                    <Col className="ml-auto mr-auto" md="10">
                                        <ul className="list-unstyled follows">
                                            <li>
                                                <Row>
                                                    <Col className="ml-auto mr-auto" lg="2" md="2" xs="4">
                                                        Baujahr
                                                    </Col>
                                                    <Col className="ml-auto mr-auto" lg="30" md="6" xs="4">
                                                        {props.cfData.constructionYear}
                                                    </Col>
                                                </Row>
                                            </li>
                                            <hr/>
                                            <li>
                                                <Row>
                                                    <Col className="ml-auto mr-auto" lg="2" md="2" xs="4">
                                                        Letzte Sanierung
                                                    </Col>
                                                    <Col className="ml-auto mr-auto" lg="30" md="6" xs="4">
                                                        {props.cfData.lastRenovationYear}
                                                    </Col>
                                                </Row>
                                            </li>
                                            <hr/>
                                            <li>
                                                <Row>
                                                    <Col className="ml-auto mr-auto" lg="2" md="2" xs="4">
                                                        Sicherheit
                                                    </Col>
                                                    <Col className="ml-auto mr-auto" lg="30" md="6" xs="4">
                                                        {props.cfData.securityComment}
                                                    </Col>
                                                </Row>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </TabPane>
                        <TabPane className="text-center" tabId="2" id="size">
                            <Row>
                                <Col className="ml-auto mr-auto" md="10">
                                    <ul className="list-unstyled follows">
                                        <li>
                                            <Row>
                                                <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                                                    Höhe
                                                </Col>
                                                <Col className="ml-auto mr-auto" lg="30" md="4" xs="4">
                                                    {props.cfData.clearanceHeight} m
                                                </Col>
                                            </Row>
                                        </li>
                                        <hr/>
                                        <li>
                                            <Row>
                                                <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                                                    Breite
                                                </Col>
                                                <Col className="ml-auto mr-auto" lg="30" md="4" xs="4">
                                                    {props.cfData.width} m
                                                </Col>
                                            </Row>
                                        </li>
                                        <hr/>
                                        <li>
                                            <Row>
                                                <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                                                    Länge
                                                </Col>
                                                <Col className="ml-auto mr-auto" lg="30" md="4" xs="4">
                                                    {props.cfData.length} m
                                                </Col>
                                            </Row>
                                        </li>
                                        <hr/>
                                        <li>
                                            <div className="text">
                                                <DimensionsModalWindow cfData={props.cfData}/>
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </TabPane>

                    </TabContent>
                </Col>
            </Row>
        </>
    )
}

export default Details;