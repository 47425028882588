/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {properties} from "../../components/Helpers/generalProperties";

// reactstrap components
import {
    Button, Col,
    Modal
} from "reactstrap";

// core components

function DimensionsModalWindow(props) {
    const [modal, setModal] = React.useState(false);
    const toggleModal = () => {
        setModal(!modal);
    };

    if (props.cfData.dimensionDetailImage)
        return (
            <>
                <Button className="btn-link ml-1" color="black" type="text" onClick={toggleModal}>
                    Weitere Details
                </Button>
                {/* Modal */}
                <Modal isOpen={modal} toggle={toggleModal} size={"lg"} scrollable={true}>
                    <div className="modal-header">
                        <button
                            aria-label="Close"
                            className="close"
                            type="button"
                            onClick={toggleModal}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                        <h5
                            className="modal-title text-center"
                            id="exampleModalLabel"
                        >
                            Abmessungen - Details
                        </h5>
                    </div>
                    <div className="modal-body text-center">
                        <div>
                            <img width={590}
                                 src={properties.media + props.cfData.objectID + "_dimensions.jpg"}
                            />
                        </div>
                    </div>

                    <div className="modal-footer text-center">
                        <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={toggleModal}
                        >
                            OK
                        </Button>
                    </div>
                </Modal>
            </>
        );
    else return (
        <>
            </>
    );
}

export default DimensionsModalWindow;
