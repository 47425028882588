/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import {API, Auth, Storage} from "aws-amplify";

// reactstrap components
import {
    Container,
    Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Maps from "../components/classifiedDetails/Maps";


const queryString = require('query-string');

var cfDataInit = {}

var first = true;


function Classified(props, search) {

    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, userHasAuthenticated] = useState(false);

    const [cfData, setCfData] = React.useState(cfDataInit);


    const toggleCfData = data => {
        if (cfData !== data) {
            setCfData(data);
        }
    };

    if (first) {
        first = false;
        console.log("process.env.NODE_ENV=" + process.env.NODE_ENV)
        switch (process.env.NODE_ENV) {
            case "development":
                console.log("development switch")

                first = false;
                // Use this code for mockbackend cf loading - working with contents or new data structure e.g.
                // fetch(properties.backend + "entities" + ".json").then(results => {
                //     return results.json()
                // }).then(data => {
                //     toggleCfData(data);
                // })

                // Use this code for live cf loading - testing error cases e.g.
                loadEntities().then(data => {
                    toggleCfData(data);
                })
                break;

            case "production":
                    loadEntities().then(data => {
                        toggleCfData(data);
                    })
                break;
        }

    }

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        // onLoad();
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });


    if (cfData == cfDataInit) {
        return (
            <>
                ... loading ...
            </>
        );
    } else {
        var cfList = cfData.map(cf => {
            return (<>
                <h4 className="title">
                    {cf.carEntry.street}, {cf.carEntry.cityDistrict}, {cf.carEntry.city}
                </h4>
                <a href={"/?cf="+cf.entityId}>
                    Alle Details hier
                </a>

                <Maps cfData={cf}/>
                    <hr/>
                    <br/>
                    <br/>
                </>
                );
        })

        return (
            <>
                <ExamplesNavbar/>
                <ProfilePageHeader cfData={cfData[0]}/>
                <div className="section profile-content">
                    <Container>
                        <Col className="text-left ml-auto mr-auto" md="7">
                            { cfList }
                        </Col>
                    </Container>
                </div>
                <DemoFooter/>
            </>
        );
    }
}

function loadEntities() {
    console.log("loadEntities")
    return API.get("entities", `/entities`);
}

export default Classified;
